import { Injectable } from "@angular/core";
import { CanActivate, Router} from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { UserRoles } from "@models/UserRoles";
import { ContextService } from "@services/context.service";

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly context: ContextService
    ) { }

    canActivate(): Observable<boolean> {
        return this.context.getLoggedInUserRole().pipe(
            take(1),
            map((userRoles: { message: string; data: string[] }) => {
                if (userRoles.data.indexOf(UserRoles.SUPER_ADMIN) === -1 && userRoles.data.indexOf(UserRoles.SUCCESS_MANAGER) === -1) {
                    void this.router.navigate(["/dashboard"]);
                    return false;
                }

                return true;
            })
        );
    }
}
