import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/core/util-services/local-storage.service";
import { LocalStorageKeys } from "@models/LocalStorageKeys";
import { InternalAnalyticsService } from "@services/internal-analytics/internal-analytics.service";
import { ContextService } from "@services/context.service";
import { SortingDirection, SortingRule } from "@models/TableFilters";
import { FeatureFlagsService } from "@services/feature-flags/feature-flags.service";
import { FeatureFlagConfig } from "@models/feature-flags/FeatureFlagConfig";
import { analyticEvents } from "@services/internal-analytics/analyticEvents";
import { TableConfig } from "./models/TableConfig";
import { Column, FilterType } from "./models/Column";

@Component({
    selector: "app-table",
    templateUrl: "table.component.html",
    styleUrls: ["table.component.scss"],
})
export class TableComponent implements OnInit {
    @Input() config: TableConfig;
    @Input() data: any[];
    @Input() currentPage = 1;
    @Input() total: number;
    @Input() isLoading: boolean;
    @Output() getPage = new EventEmitter<number>();
    @Output() getSort = new EventEmitter<{
        columnName: string;
        direction: SortingDirection;
    }>();
    @Output() getSearch = new EventEmitter<{
        columnName: string;
        searchTerm: any;
    }>();
    @Output() getFilter = new EventEmitter<{
        columnName: string;
        value: any;
    }>();
    @Output() getAction = new EventEmitter<{ action: string; data: any }>();
    @Output() getDate = new EventEmitter<{
        columnName: string;
        date: string;
    }>();
    @Input() filterClass?: string;
    @Input() tableClass?: string;
    @Input() loaderHeight?: string = "554";
    @Input() visiblePages?: number = 5;

    sorts = [];
    filters = [];
    dates = [];

    constructor(
        private router: Router,
        private localStorage: LocalStorageService,
        private analytics: InternalAnalyticsService,
        private featureFlagsService: FeatureFlagsService,
        private context: ContextService
    ) {}

    ngOnInit() {
        this.parseSorts();
        this.parseFilters();
    }

    sort(columnName: string) {
        Object.keys(this.sorts).map((sort) => {
            if (columnName !== sort) {
                this.sorts[sort] = "DESC";
            }
        });
        const sort = this.sorts[columnName];
        const direction =
            sort === "DESC" ? SortingDirection.ASC : SortingDirection.DESC;
        this.sorts[columnName] = direction;
        this.getSort.next({ columnName, direction } as SortingRule);
    }

    search(searchTerm: string, columnName: string) {
        if (!searchTerm) {
            searchTerm = "";
        }
        this.getSearch.next({ columnName, searchTerm });
        this.currentPage = 0;
    }

    filter(value: any, columnName: string) {
        this.filters[columnName] = value;
        this.getFilter.next({ columnName, value });
        this.currentPage = 0;
    }

    changeDate(date: string, columnName: string) {
        if (date) {
            this.dates[columnName] = date;
        } else {
            delete this.dates[columnName];
        }

        this.getDate.next({ columnName, date });
    }

    resolveLinkAction(action: string, param: any, lang?: string) {
        const me = this.context.getMe();
        switch (action) {
        case "live":
            if (lang) {
                window.open(
                    `${String(param) + "/?lang=" + lang}`,
                    "_blank"
                );
            } else {
                window.open(`${String(param)}`, "_blank");
            }
            break;
        case "edit":
            if (this.featureFlagsService.isFeatureFlagEnabled(FeatureFlagConfig.INTERNAL_ANALYTICS)) {
                this.analytics.triggerBrameEvent(
                    analyticEvents.campaignOpened,
                    me.email,
                    `${String(me.firstName)} ${String(me.lastName)}`,
                    param,
                ).subscribe();
            }
            void this.router.navigateByUrl(
                this.context.setCompanyViewIdInUrl(`/builder/${String(param)}`, this.context.getCompanyViewId())
            );
            break;
        case "analytics":
            if (this.featureFlagsService.isFeatureFlagEnabled(FeatureFlagConfig.INTERNAL_ANALYTICS)) {
                this.analytics.triggerBrameEvent(
                    analyticEvents.analyticsOpened,
                    me.email,
                    `${String(me.firstName)} ${String(me.lastName)}`,
                    param
                ).subscribe();
            }
            void this.router.navigateByUrl(
                this.context.setCompanyViewIdInUrl(`/campaign/details/${String(param)}`, this.context.getCompanyViewId())
            );
            break;
        }
    }

    getLinkActionParam(btns: any[], action: string) {
        return btns.filter((item) => item.action === action)[0].param;
    }

    editCampaignPermission() {
        const userRole = this.localStorage.getItem(LocalStorageKeys.USER_ROLE, []);
        return userRole.some(
            (role: string) => role === "EDITOR" || role === "SUPER_ADMIN"
        );
    }

    showButton(btns: any[], action: string) {
        const btn = btns.filter((item) => item.action === action);

        return btn.length > 0;
    }

    resolveAction(action: string, data: any) {
        this.getAction.next({ action, data });
    }

    changePage(page: number) {
        this.getPage.next(page);
    }

    getRender(column: Column, columnData: any) {
        return column.render(columnData);
    }

    getCustomActions(column: Column, columnData: any) {
        if (column.customActions) {
            return column
                .customActions(columnData)
                .filter((column: any) => column.show);
        }
        return [];
    }

    parseFilters() {
        const filterableColumns = this.config.columns.filter(
            (column) =>
                column.filterable && column.filter === FilterType.DROPDOWN
        );
        filterableColumns.map((column) => {
            this.filters[column.dataProperty] = column.filterOptions[0].id;
        });
    }

    parseSorts() {
        const sortableColumns = this.config.columns.filter(
            (column) => column.sortable
        );
        sortableColumns.map((column) => {
            this.sorts[column.dataProperty] = "DESC";
        });
    }

    ifVisibleOptions(actions: any[], columnData: any) {
        const visibleOptions = [];
        actions.map((action) => {
            if (action.show(columnData)) {
                visibleOptions.push(action);
            }
        });

        return visibleOptions.length > 0;
    }
}
