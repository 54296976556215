import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "trimWord",
})
export class TrimWordPipe implements PipeTransform {
    transform(value: string, arg1: number): string {
        return value && value.length > arg1
            ? value.substring(0, arg1) + "..."
            : value;
    }
}
