import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { UserRoles } from "@models/UserRoles";
import { ContextService } from "@services/context.service";
import { map, tap } from "rxjs/operators";
import { RouteRedirectionService } from "src/app/core/util-services/route-redirection.service";
import { CAMPAIGNS_PAGES_ACCESS } from "../constants";

const hasAccess: (roles: UserRoles[]) => boolean = (roles) => roles.some((role) => CAMPAIGNS_PAGES_ACCESS.indexOf(role) !== -1);

@Injectable()
export class CampaignsGuard implements CanActivate {
    constructor(
        private readonly context: ContextService,
        private readonly routing: RouteRedirectionService
    ) {}

    canActivate() {
        return this.context.getLoggedInUserRole().pipe(
            map((response: { data: UserRoles[] }) => response.data),
            tap((roles) => {
                const canAccess = hasAccess(roles);
                if (!canAccess) {
                    this.routing.redirect(roles);
                }
            }),
            map(hasAccess)
        );
    }
}
