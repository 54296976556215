import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { DemoService } from "../../features/register-trial/services/demo.service";
import { AuthService } from "./auth.service";

const STATUS_COMPLETED = "COMPLETED";

@Injectable()
export class DemoGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly auth: AuthService,
        private readonly demo: DemoService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.auth.isLoggedIn.pipe(
            switchMap((isLoggedIn) => {
                if (isLoggedIn) {
                    void this.router.navigate(["/"]);
                    return of(false);
                }

                if (route.queryParams && route.queryParams.uuid) {
                    return this.demo.getOffer(route.queryParams.uuid).pipe(
                        map((offer) => {
                            if (!offer || offer.status === STATUS_COMPLETED) {
                                void this.router.navigate(["/register-demo"]);
                            } else {
                                for (const key of Object.keys(offer)) {
                                    route.data.offer[key] = offer[key];
                                }
                            }

                            return true;
                        }),
                    );
                }

                return of(true);
            }),
        );
    }
}
